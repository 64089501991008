<template>
  <div>
    <div class="news-main">
      <div class="news-title">{{  newsDetail.newsTitle  }}</div>
      <div class="news-info">
        <div class="news-date"><span style="margin-right: 50px;">日期：{{  newsDetail.newsTime | dateFormat  }}
          </span><span>来源：{{  newsDetail.newsAuthor  }}</span></div>
        <div class="news-readnum">{{  newsDetail.newsNumber  }} 阅读人次</div>
      </div>
      <div class="news-content" v-html="newsDetail.newsContent"></div>
      <div class="news-link" v-if="newsDetail.newsLink">原文链接： {{  newsDetail.newsLink  }}</div>
      <el-divider class="news-bottom">已到文章底部</el-divider>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getNewsDetailApi } from "@/request/api"
export default {
  data() {
    return {
      newsDetail: {
        newsTitle: "",
        newsTime: "",
        newsAuthor: "",
        newsNumber: "",
        newsContent: "",
        newsLink: ""
      },
    };
  },
  created() {
    this.getNewsList(this.$route.params.id);
    console.log(getNewsDetailApi);
  },
  mounted() {
  },
  methods: {
    //获取新闻详情

    getNewsList(id) {
      axios.post(getNewsDetailApi, { id }, {
      }).then(res => {
        if (res.status == 200) {
          this.newsDetail = res.data.data
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    },
  }
};
</script>

<style lang="less" scoped>
.news-main {
  width: 1300px;
  margin: 0 auto;

  .news-title {
    font-size: 30px;
    line-height: 35px;
    font-family: siyuanhei;
    color: #414951;
    letter-spacing: 2px;
    padding: 50px 0;
    border-bottom: 3px solid #D2D2D2;
    text-align: center;
  }

  .news-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    color: #000;
    margin-top: 16px;

    .news-date {
      margin-right: 35px;
    }
  }

  .news-content {
    margin: 50px 0;
    font-size: 18px;
    //font-family: PingFang SC;
    //font-weight: normal;
    line-height: 35px;
    letter-spacing: 1px;

    font-s ::v-deep * {
      font-size: 20px;
      line-height: 35px;
    }

    ::v-deep img {
      max-width: 1190px;
      height: auto;
      text-align: center;
    }

    ::v-deep p {
      text-align: justify;
    }
  }


  .news-link {
    font-size: 18px;
    color: #000;
  }

  .news-bottom {
    margin: 40px 0;

    div {
      background-color: #F6F6F6;
      font-size: 18px;
      color: #000;
    }
  }
}
</style>
